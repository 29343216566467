import Vue from 'vue'
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: '',
    //   component: () => import('@/pages/Home'),
    //   meta: {title: '主页-新动传媒'}
    // },
    // {
    //   path: '/introduce',
    //   component: () => import('@/pages/Introduce'),
    //   meta: {title: '公司介绍-新动传媒'}
    // },
    {
      path: '/',
      component: () => import('@/pages/Model'),
      meta: {title: '演员模特-新动传媒'}
    },
    {
      path: '/404',
      component: () => import('@/pages/404'),
      meta: {title: '404'}
    }
  ]
})

router.beforeEach((to, _, next) => {
  document.title = to.meta.title;
  if (to.matched.length === 0) {
    next({path: '/404'})
  } else {
    next();
  }
})

export default router