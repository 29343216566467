<template>
  <keep-alive>
    <router-view />
  </keep-alive>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import "./app.css";
</style>
