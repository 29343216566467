import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

Vue.component('Layout', ()=>import('./layout'));
Vue.component('MyVideo', ()=>import('./components/MyVideo'));

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
